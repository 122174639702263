import "elm-canvas/elm-canvas.js";
import { jsPDF } from "jspdf";

import { Elm } from "./elm/Main.elm";

const app = Elm.Main.init({});

app.ports.print.subscribe(function() {
    const canvas = document.getElementsByTagName("canvas")[0];
    const dataUrl = canvas.toDataURL();

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [8.5, 11]
    });

    pdf.addImage(dataUrl, 'PNG', 0, 0, 8.5, 11);

    const pdfData = pdf.output('blob');
    const url = URL.createObjectURL(pdfData);
    const win = window.open(url, '_blank');
});

app.ports.getAverageColor.subscribe(function() {
    console.log("here!!");
    const image = document.querySelector("elm-canvas > img");
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0, image.width, image.height);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    let red = 0;
    let green = 0;
    let blue = 0;

    for (let i = 0; i < imageData.length; i += 4) {
        red += imageData[i];
        green += imageData[i + 1];
        blue += imageData[i + 2];
    }

    red /= (imageData.length / 4);
    green /= (imageData.length / 4);
    blue /= (imageData.length / 4);


    const avgColor = {
        red: Math.round(red),
        green: Math.round(green),
        blue: Math.round(blue)
    };

    console.log(`sending average color ${avgColor}`);
    app.ports.setAverageColor.send(avgColor);
});
